<template>
    <div class="numberLogon">
        <!-- 账号密码 -->
        <div class="numberLogon-main">
            <van-field class="van-input" style="margin-bottom: 0.4rem;" v-model="parmes.mobile" placeholder="请输入手机号" />
            <van-field class="van-input" type="password" v-model="parmes.password" placeholder="请输入密码" />
        </div>
        <div class="numberLogon-btn">
            <button @click="toLogin">登录</button>
        </div>
    </div>
</template>
<script>
import { Field, Toast  } from 'vant';
import { mapGetters } from 'vuex';
import LoginApi from '@/api/login/loginApi';
import { toASCII } from 'punycode';
export default {
    components: {
        [Field.name]: Field 
    },
    data () {
        return {
            parmes: {
                projectId: '',           //项目id
                ddMemberId: '',          //丁丁医生会员的id
                mobile: '',             // 手机号
                password: ''            // 密码
            },
            isLogin: false
        }
    },
    computed:{
        ...mapGetters({ 
            targetPath: 'targetPath',
        })
    },
    created(){
        const {projectId,ddMemberId} = this.$route.query;
        console.log("ddMemberId:--"+ddMemberId);
        this.parmes.projectId = projectId || '';
        this.parmes.ddMemberId = ddMemberId || '';
    },
    methods: {
        // 账号登录
        toLogin() {
            const { path } = this.$route.query
            const { parmes } = this;

            const obj = {
                mobile: '手机号',
                password: '密码'
            }
            if(this.isLogin) return false;
            for(const i in obj) {
                if(!parmes[i]) {
                    Toast(`请填写${obj[i]}`)
                    return
                }
            }
            this.isLogin = true;
            LoginApi.accountLogin({ ...this.parmes }).then(res => {
                localStorage.setItem('healthme_token', res.data.token)
                this.$store.commit('SET_TOKEN', res.data.token)
                // window.location.href = this.targetPath.path
                this.$router.replace({
                    path: path || '/',
                    query: {id: this.parmes.projectId || '', ddMemberId: this.parmes.ddMemberId || '' }
                })

            }).catch(err => {
                this.isLogin = false;
                Toast(err)
            })
        }
    }
}
</script>
<style lang="less" scope>
.numberLogon {
    padding: 0 .4rem;
    .numberLogon-main {
        margin-top: 1.76rem;  
    }
    .numberLogon-btn {
        margin-top: 2.293333rem;
        button {
            width: 100%;
            height: 1.2rem;
            font-size: 18px;
            color: #fff;
            margin-bottom: .533333rem;    
            background: #009DF3;
            border-radius: .133333rem;
            border: none;
        }
        .btn2 {
            background: none;
            border: .053333rem solid #2A87EE;
            color: #2A87EE;
        }
    }
    .van-input {
        width: 100%;
        height: 1.2rem;
        font-size: 15px;
        color: #272727;
        padding-left: .266667rem;
        background: #F7F7F7;
        margin-bottom: 0.4rem;
        &::after {
            border: none;
        }
    }
}
</style>

